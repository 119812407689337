
import { Component, Watch, Vue } from "vue-property-decorator";
import FooterComponent from "../../components/_shared/footer-component.vue";
import HeaderComponent from "../../components/_shared/header-component.vue";
import SignDemo from "../../components/sign-demo.vue";
import QuickScroll from "@/components/_shared/quick-scroll.vue";
import CookieComponent from "@/components/_shared/cookies.vue";
import i18n from "@/i18n";
@Component({
  components: {
    FooterComponent,
    HeaderComponent,
    SignDemo,
    QuickScroll,
    "cookie-component": CookieComponent,
  },
  metaInfo: {
    title: String(i18n.t("titles.notFound")),
    htmlAttrs: {
      lang: i18n.locale,
    },
    meta: [
      { charset: "utf-8" },
      {
        name: "description",
        content: String(i18n.t("description.notFound")),
      },
    ],
  },
})
export default class NotFound extends Vue {
  @Watch("$i18n.locale")
  changeTitle(): void {
    document.title = String(this.$i18n.t("titles.notFound"));
    const description = document.querySelector("meta[name='description']");
    if (description)
      description.setAttribute(
        "content",
        String(this.$i18n.t("description.notFound"))
      );
    document.documentElement.setAttribute("lang", this.$i18n.locale);
  }
}
